import { useEffect, useMemo, useState } from "react";

import PropTypes from "prop-types";

import { DataProvider, GlobalActionsProvider } from "@plasmicapp/host";

import { openLinkedInWindow, useUser } from "../utils/auth";
import { getUserFromLocalStorage } from "../utils/localStorage";

export const AuthContext = ({ children }) => {
  const [user, setUser] = useState(null);
  const { user: fetchedUser, isLoading, isError } = useUser();

  // Update the user state when fetchedUser changes
  useEffect(() => {
    if (fetchedUser) {
      setUser(fetchedUser);
    }
  }, [fetchedUser]);

  const login = () => {
    const localStorageUserData = getUserFromLocalStorage();
    if (localStorageUserData) {
      setUser(localStorageUserData);
      return;
    }
    openLinkedInWindow("Login With LinkedIn", "600", "600");
  };

  const logout = () => {
    localStorage.clear();
    setUser(null);
  };

  const actions = useMemo(
    () => ({
      login: () => login(),
      logout: () => logout(),
    }),
    []
  );

  return (
    <GlobalActionsProvider contextName="AuthGlobalContext" actions={actions}>
      <DataProvider name="auth" data={{ user, isLoading, isError }}>
        {children}
      </DataProvider>
    </GlobalActionsProvider>
  );
};

AuthContext.propTypes = {
  children: PropTypes.node.isRequired,
};
