import useSWR from 'swr';

import { getPlasmicAppUserFromToken } from '@plasmicapp/auth-api';

import {
  getUserFromLocalStorage,
  setUserFromLocalStorage,
} from './localStorage';

const LINKEDIN_SCOPE = import.meta.env.VITE_LINKEDIN_SCOPE;
const LINKEDIN_CLIENT_ID = import.meta.env.VITE_LINKEDIN_CLIENT_ID;
const LINKEDIN_REDIRECT_URI = import.meta.env.VITE_LINKEDIN_REDIRECT_URI;
const LINKEDIN_ORIGINS = import.meta.env.VITE_LINKEDIN_ORIGINS;

const LINKEDIN_BASE_URI =
  "https://www.linkedin.com/oauth/v2/authorization?response_type=code";

const AUTH_URL = `${LINKEDIN_BASE_URI}&client_id=${LINKEDIN_CLIENT_ID}&redirect_uri=${encodeURIComponent(
  LINKEDIN_REDIRECT_URI
)}&scope=${encodeURIComponent(LINKEDIN_SCOPE)}`;

export const openLinkedInWindow = (windowName, windowWidth, windoHeight) => {
  const linkedInWindow = window.open(
    AUTH_URL,
    windowName,
    `width=${windowWidth},height=${windoHeight}`
  );
  if (linkedInWindow) {
    linkedInWindow.focus();
  } else {
    console.error("Auth window closed or not opened");
  }
};

const getUserFromToken = async (token) => {
  return await getPlasmicAppUserFromToken({ token });
};

const fetchUser = async () => {
  const localStorageUserData = getUserFromLocalStorage();
  if (localStorageUserData) return localStorageUserData;

  return new Promise((resolve, reject) => {
    const handleWindowMessage = async (event) => {
      if (LINKEDIN_ORIGINS.includes(event.origin)) {
        try {
          const userData = await getUserFromToken(event.data.plasmicUser.token);
          setUserFromLocalStorage(userData);
          resolve(userData);
        } catch (error) {
          reject(error);
        } finally {
          window.removeEventListener("message", handleWindowMessage);
        }
      }
    };

    window.addEventListener("message", handleWindowMessage);
  });
};

export const useUser = () => {
  const { data: user, error, isLoading } = useSWR("user", fetchUser);

  return {
    user,
    isLoading,
    isError: error,
  };
};
