import { Layout, Spin, Typography } from "antd";

import { LoadingOutlined } from "@ant-design/icons";

const { Text } = Typography;
const { Content } = Layout;

export const LoadingPage = () => {
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 48, color: "#1890ff" }} spin />
  );

  return (
    <Layout
      style={{
        minHeight: "100vh",
        minWidth: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Content style={{ textAlign: "center", marginTop: "10vh" }}>
        <Spin indicator={antIcon} />
        <Text
          style={{
            display: "block",
            marginTop: 24,
            fontSize: 18,
            color: "#595959",
          }}
        >
          Gathering Book Recommendations Now
        </Text>
      </Content>
    </Layout>
  );
};

export default LoadingPage;
