/* eslint-disable react/prop-types */
import { Menu } from "antd";
import { useNavigate } from "react-router-dom";

// import styled from "styled-components";
import {
  BookOutlined,
  HomeOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";

// const StyledMenuItem = styled(Menu.Item)`
//   display: flex;
//   justify-content: space-around;
//   align-items: center;

//   .ant-menu-item {
//     font-size: clamp(0.8rem, 2vw, 1.2rem);
//     padding: 0.5em 1em;
//   }
//   &:hover {
//     background-color: #dde5b6 !important;
//   }
//   &.ant-menu-item-selected {
//     background-color: #adc178 !important;
//   }
// `;

export const CustomMenu = ({
  className,
  isMenuCollapsed,
  menuStyle,
  menuItemStyle,
  isHorizontal,
}) => {
  const items = [
    {
      key: "/",
      label: "Welcome Page",
      icon: !isHorizontal && (
        <HomeOutlined style={{ fontSize: "1.2rem", color: "#6C584C" }} />
      ),
      style: menuItemStyle,
    },
    {
      key: "/recommendations",
      label: "Recommendations",
      icon: !isHorizontal && (
        <BookOutlined style={{ fontSize: "1.2rem", color: "#6C584C" }} />
      ),
      style: menuItemStyle,
    },
    {
      key: "/profile",
      label: "Profile",
      icon: !isHorizontal && (
        <UserOutlined style={{ fontSize: "1.2rem", color: "#6C584C" }} />
      ),
      style: menuItemStyle,
    },
    {
      key: "/about-us",
      label: "About Us",
      icon: !isHorizontal && (
        <TeamOutlined style={{ fontSize: "1.2rem", color: "#6C584C" }} />
      ),
      style: menuItemStyle,
    },
  ];

  const navigate = useNavigate();
  const handleMenuClick = (event) => {
    navigate(event.key);
  };
  return (
    <Menu
      className={className}
      style={menuStyle}
      defaultSelectedKeys={window.location.pathname}
      mode={isHorizontal ? "horizontal" : "vertical"}
      inlineCollapsed={isMenuCollapsed}
      items={items}
      onClick={handleMenuClick}
    >
      {/* {items.map((item) => {
        return (
          <StyledMenuItem
            onClick={() => navigate(item.key)}
            key={item.key}
            icon={item.icon}
            style={menuItemStyle}
          >
            {item.label}
          </StyledMenuItem>
        );
      })} */}
    </Menu>
  );
};

export default CustomMenu;
