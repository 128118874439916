export const getUserFromLocalStorage = () => {
  return localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;
};

export const setUserFromLocalStorage = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
};

export const isTokenExpired = (user) => {
  const tokenExpirationDate = user?.customProperties?.token_expiration_date;
  if (!tokenExpirationDate) return true;
  const currentDate = Date.now();
  if (parseInt(tokenExpirationDate) <= currentDate) {
    localStorage.clear();
    return true;
  }
};
