import { Layout, Spin, Typography } from "antd";

import { LoadingOutlined } from "@ant-design/icons";

const { Text } = Typography;
const { Content } = Layout;

// eslint-disable-next-line react/prop-types
export const LoadingModal = ({ className }) => {
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 48, color: "#1890ff" }} spin />
  );

  return (
    <Layout
      className={className}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Content style={{ textAlign: "center" }}>
        <Spin indicator={antIcon} />
        <Text
          style={{
            display: "block",
            fontSize: 18,
            color: "#595959",
          }}
        >
          Helping other users to climb the ladder...
        </Text>
      </Content>
    </Layout>
  );
};

export default LoadingModal;
