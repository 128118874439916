import { Descriptions, Skeleton } from "antd";
import CustomMenu from "src/components/custom/CustomMenu";
import LoadingModal from "src/components/custom/LoadingModal";
import Toggle from "src/components/custom/Toggle";

import { initPlasmicLoader } from "@plasmicapp/loader-react";

import { AuthContext } from "./services/auth/AuthContext";

export const PLASMIC = initPlasmicLoader({
  projects: [
    {
      id: "3GeR56sZeHMLAo7djfQwH4", // ID of a project you are using
      token:
        "iSJxmRU5hdeBunxu6ExqdrJBa29ZJaqm1vdIu8FVkh4kNMAERntbT7WuW44Vdw4t7jOFXjpbT1csU2IErF0w", // API token for that project
    },
  ],
  // Fetches the latest revisions, whether or not they were unpublished!
  // Disable for production to ensure you render only published changes.
  preview: true,
});

PLASMIC.registerComponent(Skeleton, {
  name: "Skeleton",
  props: {
    active: "boolean",
    loading: "boolean",
  },
  importPath: "antd",
});

PLASMIC.registerComponent(Descriptions, {
  name: "Descriptions",
  props: {
    contentStyle: "object",
    labelStyle: "object",
    items: "object",
  },
  importPath: "antd",
});

PLASMIC.registerComponent(CustomMenu, {
  name: "CustomMenu",
  importPath: "src/components/custom/CustomMenu",
  props: {
    isMenuCollapsed: "boolean",
    isHorizontal: "boolean",
    menuStyle: "object",
    menuItemStyle: "object",
  },
});

PLASMIC.registerComponent(Toggle, {
  name: "Toggle",
  importPath: "src/components/custom/Toggle",
  props: {
    className: "string",
    checked: "boolean",
    size: "string",
    loading: "boolean",
  },
});

PLASMIC.registerComponent(LoadingModal, {
  name: "LoadingModal",
  importPath: "src/components/custom/LoadingModal",
  props: {
    className: "string",
  },
});

PLASMIC.registerGlobalContext(AuthContext, {
  // name should match GlobalActionsProvider contextName
  name: "AuthGlobalContext",
  // props should match AuthGlobalContextProps
  props: {},
  // providesData should be true if the global context has a DataProvider
  providesData: true,
  // globalActions should match the global context's GlobalActionsProvider
  globalActions: {
    login: { parameters: [] },
    logout: { parameters: [] },
  },
  importPath: "src/services/auth/AuthProvider",
});
