import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

export const ProtectedRoute = ({ children, token }) => {
  return token ? children : <Navigate to="/forbidden" replace />;
};

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
  token: PropTypes.string.isRequired,
};
