import "./index.css";

import { StrictMode } from "react";

import { createRoot } from "react-dom/client";

import * as Sentry from "@sentry/react";

import App from "./App.jsx";
import { AuthContext } from "./services/auth/AuthContext.jsx";

Sentry.init({
  dsn: "https://81abeb20a066e12c68be353927ddfe6e@o4508240359718912.ingest.us.sentry.io/4508279989993472",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/(www\.)?ladderreads\.com\//,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

createRoot(document.getElementById("root")).render(
  <StrictMode>
    <AuthContext>
      <App />
    </AuthContext>
  </StrictMode>
);
