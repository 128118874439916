/* eslint-disable react/prop-types */
import { Switch } from "antd";

export const Toggle = ({ className, checked, size, loading }) => {
  return (
    <Switch
      className={className}
      defaultChecked={checked}
      size={size}
      loading={loading}
    />
  );
};

export default Toggle;
